import React, { useEffect } from "react"
import loadable from '@loadable/component'

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import keywords from "../constants/keywords"
import BtnArrow from "../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../assets/images/button_arrowbottom.svg"

const faqPage = () => {

    return (
        <>
            <Layout black={false}>
                <SEO
                    title={"F&S Digital is Now Differnt Systems | F&S Digital"}
                    description={"We're excited to unveil our transformation to 'Differnt Systems' as we celebrate our 5th anniversary!"}
                    customKeywords={keywords.faq.keywords}
                />

                <div class="offcanvas-wrap">

                    {/* accordion */}
                    <section className="pb-15 py-lg-15 py-xl-15">
                        <div style={{ paddingTop: '4%' }} className="container mt-2 mb-0 pb-0">
                            <div className="row mb-10">
                                <div className="col text-left">
                                    <span className="hero-label badge bg-muted-purple text-white rounded-pill mb-3">Company News</span>
                                    <h1 className="mb-3">F&S Digital is Now Differnt Systems</h1>
                                    <div className="company-news-section">
                                        <p>Dear Friends,</p>

                                        <p>As 2024 unfolds, we find ourselves at a momentous milestone—F&S Digital is turning five. What began as a dream at Stockton University in Galloway, New Jersey has flourished far beyond our wildest imaginings. Our ambition to pioneer in the realm of technology for our local community has transformed into a journey of unexpected growth, challenges, and joy.</p>

                                        <p>From the outset, our mission was clear, yet the path we've traveled has been anything but predictable. F&S Digital has not just grown; it has evolved, extending its reach across new geographical landscapes, diversifying into various industries, and broadening our spectrum of services. We've had the privilege of welcoming an array of remarkable individuals into our team, forging fruitful partnerships, and serving incredible clients who continually push us to excel.</p>

                                        <p>This journey, marked by relentless innovation and the pursuit of excellence, owes its success to you. Your trust, support, and collaboration have been the cornerstone of our achievements. As we pondered the best way to celebrate this significant anniversary, we looked inward, reflecting on the essence of our journey and the myriad of individuals who fuel our passion every day. It became clear that F&S Digital, as a name, no longer encapsulated the breadth of our aspirations or the depth of our offerings. We’re more than just a digital company; and our interactions with you have always been special—a "different" experience, as many have noted.</p>

                                        <p>With hearts full of excitement and gratitude, we are thrilled to announce our rebranding to "Differnt" in 2024. Differnt is not just a name; it is our promise to you—to remain at the forefront of innovation, to continue nurturing relationships built on trust and care, and to always cherish the uniqueness of our journey together.</p>

                                        <p>As we step into this new chapter, we look forward to celebrating many more milestones with you, and to contributing to your success in every way we can.</p>

                                        <p>Thank you, from the bottom of our hearts, for being part of our story. Here's to the many adventures that lie ahead, and to making a difference together, each and every day.</p>

                                        <p>Warmest regards,<br />Francisco, Frank, Sage, and the entire F&S (now Differnt) Team</p>
                                    </div>
                                    {/* <a href="/contact/" className='button-link'>More Questions? Contact Us
                                    <div className="arrow_movement">
                                        <img src={BtnArrow} alt=''/>
                                        <img src={BtnArrowbottom} alt=''/>
                                    </div>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    )
}

export default faqPage
